import React from 'react';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { Button } from './layout/StyledComponents';

const StyledPayments = styled.div`
    display: flex;
    background: ${colors.yellow};
    flex-direction: column;
    align-items: center;
    padding: 3.5em 0em 3em;

    h2 {
        padding: 0em 1em;
        line-height: 130%;
        font-size: 24px;
        text-align: center;
    }
    .gatsby-image-wrapper{
        overflow: visible;
    }
    .apply-btn{
        position: absolute;
        font-size: 10px;
        padding: 5px;
        margin: 5px 50px;
    }
    overflow-x: hidden;

    .payments {
        margin-top: 32px;
        overflow-x: scroll;
        padding: 0 2em;
        width: 100vw;
        display: flex;
        //hide scroll bar
        -ms-overflow-style: none;
        gap: 16px;
        &::-webkit-scrollbar {
            display: none;
        }
        .lending {
        }
        .credit {
            margin-right: 6px;
        }
        .apple,
        .cash {
            margin: 0 10px;
        }
        .cash {
            margin-right: 16px;
        }
        .aetna {
            padding: 5px;
        }
        a {
            flex-shrink: 0;
        }
        div {
            flex-shrink: 0;
            height: 30px;
            width: auto;
            img {
                width: 100%;
            }
        }
    }
    .no-insurance h2 {
        margin: 15px 0 20px;
    }
    .no-insurance button {
        margin: 20px auto 0;
    }
    .no-insurance {
        width: 100%;
        text-align: center;
        padding-top: 30px;
        border-top: 15px solid #fff;
    }
    @media ${device.tablet} {
    }
    @media ${device.laptop} {
        padding: 4em 0em 3em;
        .payments {
            padding: 0 0em;
            justify-content: space-evenly;
            flex-wrap: wrap;
            width: 1200px;
            overflow-x: none;
            gap: 42px 120px;

            .apple,
            .cash {
                margin: 0 0px;
            }
            .cash {
                margin-right: 0px;
            }
            div {
                width: auto;
                height: 30px;
            }
        }
    }
    @media ${device.laptopL} {
    }
`;

export const Payments: React.FC = () => {
    return (
        <StyledPayments>
            <h2>Accepted Payments & Insurance Plans</h2>
            <div className="payments payment-types">
                <StaticImage
                    className="credit"
                    placeholder="none"
                    src="../images/svgs/credit-card.svg"
                    alt="black card"
                />

                <StaticImage
                    className="apple"
                    placeholder="none"
                    src="../images/svgs/apple-pay.svg"
                    alt="apple pay logo"
                />
                <StaticImage
                    className="cash "
                    placeholder="none"
                    src="../images/svgs/cash.svg"
                    alt="cash symbol"
                />
            </div>
            <div className="payments">
                <a
                    target="__blank"
                    rel="noopener noreferrer"
                    href="https://carecredit.com/go/QRM669"
                >
                    <StaticImage
                        className="carecredit"
                        placeholder="none"
                        src="../images/svgs/carecredit.svg"
                        alt="black card"
                    />
                    <Button className='apply-btn' type='button'>
                        Apply Now
                    </Button>
                </a>
                <a
                    target="__blank"
                    rel="noopener noreferrer"
                    href="https://pay.withcherry.com/magic-smile-dental?utm_source=practice&m=21355"
                >
                    <StaticImage
                        className="carecredit"
                        placeholder="none"
                        src="../images/svgs/Logo-Primary-1.svg"
                        alt="Cherry card"
                    />
                    <Button className='apply-btn' type='button'>
                        Apply Now
                    </Button>
                </a>
                {/* <a
                    href="https://www.lendingclub.com/patientsolutions/app/check-your-rate?user=applicant&clientid=291649"
                    rel="noopener noreferrer"
                    target="_blank"   >
                    <StaticImage
                        className="lending"
                        placeholder="none"
                        src="../images/svgs/lending-club.svg"
                        alt="black card"
                    />
                    <Button className='apply-btn' type='button'>
                        Apply Now
                    </Button>
                </a> */}
                {/* <a
                    href=" https://go.alphaeoncredit.com/practice-application/38061"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <StaticImage
                        className="alpheon"
                        placeholder="none"
                        src="../images/alphaeon-logo.png"
                        alt="alphaeon credit logo"
                    />
                    <Button className='apply-btn' type='button'>
                        Apply Now
                    </Button>
                </a> */}
                <a
                    href="https://www.aetna.com/"
                    rel="noopener noreferrer"
                    target="_blank"   >
                    <StaticImage
                        placeholder="none"
                        className="aetna"
                        src="../images/svgs/aetna.svg"
                        alt="aetna logo"
                    />
                </a>
                {/* <StaticImage
                    placeholder="none"
                    className="metlife"
                    src="../images/svgs/metlife.svg"
                    alt="metlife logo"
                /> */}
                <a
                    href="https://www.deltadental.com/"
                    rel="noopener noreferrer"
                    target="_blank"   >
                    <StaticImage
                        placeholder="none"
                        src="../images/svgs/delta-dental.svg"
                        alt="delta dental logo"
                    />
                </a>
                
            </div>
            <br></br>
            <br></br>
            <div className="no-insurance">
                <h2>No Insurance? No Worries!</h2>
                <p>
                    Join our In-Office membership<br></br>
                    Enjoy bi-annual checkups and cleanings for the whole family,<br></br>
                    in addition to discounts on restorative and cosmetic treatments.
                </p>
                <p>
                    <a
                        href=" https://magic-smile-dental.illumitrac.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <Button>
                            Get Membership
                        </Button>
                    </a>
                </p>
            </div>
        </StyledPayments>
    );
};
