import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './StyledComponents';

const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 1.25em 0.5em 1.25em 1.25em;
    p {
        margin-right: 0.75em;
        font-weight: 600;
    }
    img {
        height: 16px;
    }
    @media ${device.laptop} {
        margin: 0 0em;
        align-self: flex-start;
    }
`;

export const ArrowButton: React.FC = ({ children }) => {
    return (
        <StyledButton>
            <p style={{ color: `${colors.white}` }}>{children}</p>
            <StaticImage
                src="../../images/svgs/arrow-right-white.svg"
                placeholder="none"
                alt="white arrow pointing right"
            />
        </StyledButton>
    );
};
