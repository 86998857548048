import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { ArrowButton } from './layout/ArrowButton';

const StyledHomeDoctor = styled.div`
    padding: 2.4em 0.75em 4em;
    .card {
        display: none;
    }
    span {
        color: ${colors.blueLight};
        font-weight: 600;
    }
    .p-tag {
        margin: 1.5em 0em 1.5em;
    }
    .doctor-image {
        margin-bottom: 2em;
        border-radius: 15px;
    }
    .invisalign-left,
    .invisalign-right {
        display: none;
    }
    @media ${device.tablet} and (max-width: 1024px) {
        padding: 0em 1em;
        margin: 2em auto;
    }
    @media ${device.laptop} {
        button {
            margin-top: 1.5em;
        }
        position: relative;
        .invisalign-left,
        .invisalign-right {
            display: block;
            position: absolute;
            z-index: -1;
        }
        .invisalign-left {
            top: 14em;
            left: 0em;
        }
        .invisalign-right {
            top: 14em;
            right: 0em;
        }
        padding: 0em 1em;
        display: flex;
        padding: 0em 3em;
        .text {
            padding: 0em 2em;
        }

        .doctor-image {
            min-width: 415px;
            height: 380px;
        }
        .card {
            display: block;
            position: absolute;
            left: 2em;
            border-radius: 15px;
        }
        .card:first-of-type {
            left: 0%;
            z-index: 0;
            background: #e6eff2;
            transform: rotate(5.5deg);
        }
        .card:nth-child(2) {
            z-index: -1;
            left: 0%;
            top: 1%;
            background: #cedae3;
            transform: rotate(12.13deg);
        }
        .doctor-container {
            img {
                z-index: 0;
            }

            position: relative;
        }
    }
    @media ${device.laptopL} {
        padding: 2em 5em 4em;
        .doctor-image {
            min-width: 615px;
            height: 440px;
            margin-right: 3.4em;
        }
        .text {
            justify-content: center;
            display: flex;
            flex-direction: column;
            padding: 0em 0em 0em;
        }
        .card {
            width: 615px;
            height: 440px;
        }
    }
    @media ${device.desktop} {
        padding: 2em 5em 4em;

        .text {
            padding: 0em 4em 0em;
        }
    }
    @media ${device.desktopL} {
        padding: 2em 12em 4em;
        .text {
            padding: 0em 8em 0em 4em;
        }
    }
`;

export const HomeDoctor: React.FC = () => {
    return (
        <StyledHomeDoctor>
            <div className="doctor-container">
                <div className="card"></div>
                <div className="card"></div>
                <StaticImage
                    className="doctor-image"
                    quality={100}
                    src="../images/anatoly-bensianoff.jpeg"
                    alt="Dr. Anatoly Bensianoff"
                    placeholder="none"
                />
            </div>
            <StaticImage
                quality={100}
                className="invisalign-left"
                src="../images/invisalign-left.png"
                placeholder="none"
                alt="invisalign set"
            />
            <StaticImage
                quality={100}
                className="invisalign-right"
                src="../images/invisalign-right.png"
                placeholder="none"
                alt="invisalign set"
            />
            <div className="text">
                <span>ABOUT OUR DOCTOR</span>
                <h2>Anatoly Bensianoff</h2>
                <p>
                    Dr. Anatoly Bensianoff earned his Doctor of Dental Surgery Degree from the NYU
                    College of Dentistry in 2001 and completed his residency at Staten Island
                    Hospital in 2001. Drawn to dentistry after volunteering as a resident’s
                    assistant in a dental clinic, Dr. Bensianoff loves to help patients alleviate
                    pain and educate them, giving them the tools they need to maintain a healthy
                    mouth for their lifetime.
                </p>
                <Link to="/about-us/meet-the-doctors/">
                    <ArrowButton>Read more about our doctor</ArrowButton>
                </Link>
            </div>
        </StyledHomeDoctor>
    );
};
