import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { ArrowButton } from './layout/ArrowButton';

const StyledHomeTechnology = styled.div`
    z-index: 0;
    padding: 20px;
    background: ${colors.blueExtraDark};
    button {
        margin: 2em auto 2em;
    }
    .underlayer {
        display: none;
    }
    .photos {
        overflow-x: scroll;
    }
    h2,
    p {
        color: ${colors.white};
    }
    .top-text,
    .image-label {
    }
    h2 {
        padding-top: 1em;
    }
    .photos {
    }

    overflow-x: scroll;
    .scroll {
        .shadow {
            border-radius: 15px;
            background: linear-gradient(
                    0deg,
                    #1a2c41 0%,
                    rgba(26, 44, 65, 0.17) 51.62%,
                    rgba(255, 255, 255, 0) 111.6%
                ),
                url(image.png);
            border-radius: 15px;
            width: 97.8%;
            transition: 0.2s ease-in;
            height: 100%;
            cursor: pointer;
            position: absolute;
            z-index: 1;
        }
        width: 100vw;
        display: flex;
        width: 1015px;
        padding: 1em 0em 2em 1em;
        div {
            border-radius: 15px;
            overflow: hidden;
            margin-right: 0.5em;
            p {
                opacity: 1;
            }
        }
        div:hover {
            img {
                transform: scale(1.1);
            }

            p {
                transform: translateY(-5px);
                opacity: 0.6;
            }
        }

        img {
            transition: 0.1s ease-in;

            border-radius: 15px;

            height: 364px;
            cursor: pointer;
        }
        .image-label {
            font-size: 20px;
            color: ${colors.white};
            position: absolute;
            bottom: 0.5em;
            z-index: 2;
            font-weight: 600;
        }
        .image-label {
            padding-left: 1em;
        }
        .second {
            position: relative;
        }
    }
    @media ${device.tablet} {
        position: relative;
        top: -8em;
        padding-top: 6em;
        z-index: 0;

        .underlayer {
            border-radius: 15px;
            z-index: -2;
            display: block;
        }
        button {
            margin: 2em auto 0em;
        }
        .second {
            position: relative;
            top: 3em;
        }
        .top-text {
            padding-left: 1.5em;
        }
        h2 {
            padding-left: 0.5em;
        }
        .scroll {
            margin: 0 auto;
            width: 100vw;
            display: flex;
            width: 1015px;
            padding: 1em 0em 8em 1em;
        }
        button {
            margin-bottom: 3em;
        }
    }
    @media ${device.laptopL} {
        button {
            margin-bottom: 0em;
        }
        position: relative;
        top: -14em;
        padding-top: 6em;
        .scroll {
            margin: 0 auto;

            div {
                cursor: pointer;
            }
        }
        .text {
            margin-top: 10em;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            p {
                width: 531px;
                position: relative;
            }
            h2 {
                position: relative;
                right: 4.8em;
                padding-top: 0em;
            }
        }
    }
`;

export const HomeTechnology: React.FC = () => {
    return (
        <StyledHomeTechnology>
            <div className="text">
                <h2>Our Technology</h2>
                <p className="top-text">
                    Our doctors carefully select technologies proven to deliver the finest
                    experience to their patients. This means comfortable procedures and predictable
                    results. A winning formula, you&apos;ll find that contemporary dental care with
                    Magic Smile Dental leaves you smiling.
                </p>
            </div>
            <div className="photos">
                <div className="scroll">
                    <Link to="/technology/itero-intraoral-scanners/">
                        <div className="image">
                            <div className="shadow"></div>
                            <StaticImage
                                imgStyle={{ borderRadius: '15px' }}
                                className="underlayer"
                                quality={100}
                                src="../images/hometech-1.jpeg"
                                placeholder="none"
                                alt="dental imaging screen"
                            />
                            <StaticImage
                                imgStyle={{ borderRadius: '15px' }}
                                quality={100}
                                src="../images/hometech-1.jpeg"
                                placeholder="none"
                                alt="dental imaging screen"
                            />
                            <p className="image-label">iTero</p>
                        </div>
                    </Link>
                    <Link to="technology/3d-imaging/">
                        <div className="second image">
                            <div className="shadow"></div>
                            <StaticImage
                                imgStyle={{ borderRadius: '15px' }}
                                className="underlayer"
                                quality={100}
                                src="../images/hometech-2.jpeg"
                                placeholder="none"
                                alt="dental x-ray machine"
                            />
                            <StaticImage
                                imgStyle={{ borderRadius: '15px' }}
                                quality={100}
                                src="../images/hometech-2.jpeg"
                                placeholder="none"
                                alt="dental x-ray machine"
                            />
                            <p className="image-label">Cone Beam / 3D X-Ray</p>
                        </div>
                    </Link>
                    <Link to="/periodontics/lanap-procedure/">
                        <div className="image">
                            <div className="shadow"></div>
                            <StaticImage
                                imgStyle={{ borderRadius: '15px' }}
                                className="underlayer"
                                quality={100}
                                src="../images/full-mouth-reconstruction.jpeg"
                                placeholder="none"
                                alt="dr holding LANAP tool"
                            />
                            <StaticImage
                                imgStyle={{ borderRadius: '15px' }}
                                quality={100}
                                src="../images/full-mouth-reconstruction.jpeg"
                                placeholder="none"
                                alt="dr holding LANAP tool"
                            />
                            <p className="image-label">LANAP</p>
                        </div>
                    </Link>
                    <Link to="/periodontics/lanap-procedure/">
                        <div className="second image">
                            <div className="shadow"></div>
                            <StaticImage
                                imgStyle={{ borderRadius: '15px' }}
                                className="underlayer"
                                quality={100}
                                src="../images/3shape.jpg"
                                placeholder="none"
                                alt="3Shape Trios Intraoral Scanner"
                            />
                            <StaticImage
                                imgStyle={{ borderRadius: '15px' }}
                                quality={100}
                                src="../images/3shape.jpg"
                                placeholder="none"
                                alt="3Shape Trios Intraoral Scanner"
                            />
                            <p className="image-label">3Shape Trios Intraoral Scanner</p>
                        </div>
                    </Link>
                    <Link to="/periodontics/lanap-procedure/">
                        <div className="second image">
                            <div className="shadow"></div>
                            <StaticImage
                                imgStyle={{ borderRadius: '15px' }}
                                className="underlayer"
                                quality={100}
                                src="../images/imgpsh_fullsize_anim-1.jpeg"
                                placeholder="none"
                                alt="LASER GUM TREATMENT"
                            />
                            <StaticImage
                                imgStyle={{ borderRadius: '15px' }}
                                quality={100}
                                src="../images/imgpsh_fullsize_anim-1.jpeg"
                                placeholder="none"
                                alt="LASER GUM TREATMENT"
                            />
                            <p className="image-label">LASER GUM TREATMENT</p>
                        </div>
                    </Link>
                </div>
            </div>
            <Link to="/technology/">
                <ArrowButton>Read more about our technology</ArrowButton>
            </Link>
        </StyledHomeTechnology>
    );
};
