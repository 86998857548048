import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';

const StyledButton = styled(Button)``;

const StyledHomeHero = styled.div`
    .button {
        z-index: 20000;
    }
    .arrow {
        display: none;
    }
    .location {
        display: flex;
        align-items: center;
        margin-top: 0.24em;
        p {
            position: relative;
            margin-left: 0.5em;
            font-size: 14px;
            bottom: 0.3em;
            color: #6e8385;
            text-decoration: underline;
        }
    }
    p {
        font-size: 14px;
        line-height: 25px;
        margin-top: 1em;
    }
    h1 {
        font-weight: 700;
        line-height: 44.8px;
        font-size: 40px;
        margin: 0.25em 0em 0.5em;
    }
    .right-column {
        position: relative;
    }
    .left-column {
        padding: 2em 0.75em;
        position: relative;
        .ok {
            position: relative;
        }
        .languages {
            display: flex;
            position: absolute;
            top: 2.5em;
            width: 500px;
            align-items: center;
            font-size: 14px;
            color: #6e8385;
        }
        h4 {
            color: ${colors.blueLight};
            font-weight: 600;
            font-size: 14px;
            display: flex;
            align-items: center;
            .blue-line {
                background: ${colors.blueLight};
                width: 120px;
                margin-left: 1em;
                height: 1.5px;
            }
        }
        .stars {
            margin-left: 8px;
            position: relative;
            top: -2px;
            display: flex;
            column-gap: 4px;
            > div {
                width: 14px;
            }
        }

        .girl-phone {
            margin: 1em 0em;
        }
        span {
            padding-top: 2em;
        }
        button {
            margin: 1em 0em;
        }
    }
    .bubbles {
        position: absolute;
        bottom: 2em;
        display: flex;
        .kim {
            position: relative;
            bottom: 2.75em;
        }
        .bensianoff {
            position: relative;
            bottom: 1.5em;
        }

        div {
            width: 117px;
            background: rgba(216, 228, 242, 0.7);
            backdrop-filter: blur(38px);
            border-radius: 10px;
            font-weight: 600;
            margin: 0em 0.5em;
            font-size: 12px;
            padding: 1em 0.75em;
            color: ${colors.blueDark};
        }
    }

    @media ${device.mobileL} {
        .bubbles {
            div {
                margin: 0em 1em;
            }
        }
    }
    @media ${device.tablet} {
        .left-column {
            .languages {
                top: 3em;
            }
            button {
                margin-bottom: 2em;
            }
        }
        padding-top: 1em;
        .bubbles {
            .kim {
                left: 6.5em;
                bottom: 10em;
            }
            .lee {
                position: relative;
                left: 12em;
                bottom: 0em;
            }
            .bensianoff {
                left: 20em;
                bottom: 4em;
            }
        }
    }
    @media ${device.laptop} {
        .bubbles {
            div {
                font-size: 16px;
                margin: 0em 0.5em;
                width: 180px;
                padding: 1em 0.75em;
            }
            .kim {
                padding-right: 1em;
                width: 165px;
                left: 2.5em;
                bottom: 15em;
            }
            .lee {
                position: relative;
                left: 4em;
                bottom: 2em;
            }
            .bensianoff {
                left: 12em;
                bottom: 6em;
            }
        }
        h1 {
            margin: 0em;
            font-size: 40px;
            width: 90%;
            line-height: 44.9px;
        }
        .arrow-container {
            position: relative;
        }

        .arrow {
            z-index: 200;
            position: absolute;
            bottom: 10.5em;
            left: 20em;
            display: block;
        }
        p:nth-of-type(2) {
            display: none;
        }
        .left-column {
            button {
                margin: 0em 0em 2.5em;
            }
            padding-left: 3.5em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            .text {
                display: none;
            }
        }
        .team {
            min-height: 640px;
            height: 8.5vh;
            width: 55vw;
        }
        display: flex;
        flex-direction: row-reverse;
        .girl-phone {
            height: 246px;
            width: 450px;
        }
        .location {
            margin-bottom: 1.5em;
        }
    }

    @media ${device.desktop} {
        .left-column {
            padding-left: 5em;
        }
        .team {
            height: 87.5vh;
            width: 55vw;
        }
        .arrow {
            bottom: 14.5em;
            left: 23.5em;
        }

        h1 {
            margin-top: 0em;
        }
        .girl-phone {
            padding-top: 3em;
            height: 346px;
            width: 530px;
        }
    }
    @media ${device.desktopL} {
        .left-column {
            .arrow {
                bottom: 17.5em;
                left: 28.5em;
            }
        }
        .girl-phone {
            padding-top: 3em;
            height: 386px;
            width: 620px;
        }
    }
`;

export const HomeHero: React.FC = () => {
    return (
        <StyledHomeHero>
            <div style={{ marginTop: '-.8em' }} className="column right-column">
                <StaticImage
                    className="team"
                    placeholder="none"
                    quality={100}
                    src="../images/Office-Picture1.jpg"
                    alt="three staff members smiling"
                />
                {/* <div className="bubbles">
                    <div className="kim">Dr. Victor Kim DMD</div>
                    <div className="lee">Dr. Vanessa Lee DMD</div>
                    <div className="bensianoff">Dr. Anatoly Bensianoff DDS</div>
                </div> */}
            </div>
            <div className="column left-column">
                <h4>
                    MAGIC SMILE DENTAL{' '}
                    <div className="stars">
                        <StaticImage src="../images/svgs/star.svg" placeholder="none" alt="star" />
                        <StaticImage src="../images/svgs/star.svg" placeholder="none" alt="star" />
                        <StaticImage src="../images/svgs/star.svg" placeholder="none" alt="star" />
                        <StaticImage src="../images/svgs/star.svg" placeholder="none" alt="star" />
                        <StaticImage src="../images/svgs/star.svg" placeholder="none" alt="star" />
                    </div>
                </h4>
                <h1>
                    Your Friendly <span className="blue-medium">Cosmetic</span> &{' '}
                    <span className="blue-medium">General Dentist </span>
                    in Linden New Jersey
                </h1>
                <p className="text">
                    Improve your confidence and overall health with tooth restoration options from
                    Magic Smile Dental.
                </p>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.google.com/maps/place/Magic+Smile+Dental/@40.6332565,-74.2602334,17z/data=!3m1!4b1!4m5!3m4!1s0x89c3b30231c43b21:0x5ea8101c1f4c4f62!8m2!3d40.6332565!4d-74.2580447"
                >
                    <div className="location">
                        <StaticImage
                            src="../images/svgs/map-marker-orange.svg"
                            alt="map marker icon"
                            placeholder="none"
                        />
                        <p>515 North Wood Ave, Suite 102, Linden, NJ 07036</p>
                    </div>
                </a>
                <div className="ok">
                    <StyledButton className="button">
                        <a style={{ color: 'white' }} href="tel:9084865000">
                            <StaticImage
                                src="../images/svgs/mobile-white.svg"
                                alt="white mobile phone"
                                placeholder="none"
                            />
                            Call Us at (908) 486-5000
                        </a>
                    </StyledButton>

                    <span className="languages">
                        <StaticImage
                            src="../images/svgs/ribbon.svg"
                            style={{ marginRight: '.75em' }}
                            placeholder="none"
                            alt="ribbon icon"
                        />
                        We speak Spanish, Polish, Russian, and Ukranian
                    </span>
                </div>

                <div className="arrow-container">
                    <StaticImage
                        className="arrow"
                        src="../images/svgs/arrow-zip.svg"
                        alt="arrow animation"
                        placeholder="none"
                    />
                    <StaticImage
                        className="girl-phone"
                        imgStyle={{ borderRadius: '15px' }}
                        src="../images/girl-on-phone.jpeg"
                        alt="girl talking on phone"
                        placeholder="none"
                    />
                </div>
            </div>
        </StyledHomeHero>
    );
};
