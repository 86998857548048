import React from 'react';
import styled from '@emotion/styled';
import { device } from './layout/GlobalStyles';

const StyledDifferent = styled.div`
    z-index: 2;
    h2 {
        padding: 0em 0.25em;
    }
    ul {
        margin: 1em 0em;
        padding-left: 2.5em;
    }
    li {
        list-style-type: disc;
    }
    @media ${device.tablet} {
        h2 {
            padding: 0em 0.75em;
        }
        iframe {
            z-index: 1;
            border-radius: 15px;
            width: 75%;
            position: relative;
            left: 4em;
            margin: 2em auto;
        }
        ul {
            margin: 1em 0em;
            padding-left: 3.5em;
        }
    }
    @media ${device.laptop} {
        margin-left: 5em;
        iframe {
            position: relative;
            left: -4em;
            margin: 0em auto;
        }
        padding: 4em 0em;
        ul {
            margin: 3em 0em 4em;
        }
    }
    @media ${device.laptopL} {
        iframe {
            border-radius: 15px;
            width: 524.74px;
            margin: 0 auto;
            margin-top: 6em;
            height: 475px;
            margin-left: 7em;
        }
        display: flex;
        .text {
            margin-right: 1.27em;
            margin-left: 4em;
        }
    }
    @media ${device.desktop} {
        iframe {
            border-radius: 15px;
            width: 654.74px;
        }
    }
`;

export const HomeDifferent: React.FC = () => {
    return (
        <StyledDifferent>
            <div className="text">
                <h2>What Makes Us Different?</h2>
                <ul>
                    <li>State of art office with high quality care</li>
                    <li>Family Dentistry</li>
                    <li>Board specialized Oral Surgeon on staff</li>
                    <li>All on X cases, implants, wisdom tooth extractions</li>
                    <li>Invisalign Gold providers</li>
                    <li>iTero digital scanner-no more impressions</li>
                    <li>LANAP ® LASER TREATMENT Specialist (gum disease treatment)</li>
                    <li>
                        We aim for long term Doctor patient relationship. Have been in business
                        since 2007.
                    </li>
                </ul>
            </div>
            <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/Wc0-IX20Drs"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </StyledDifferent>
    );
};
