import * as React from 'react';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { HomeHero } from '../components/HomeHero';
import { TopServices } from '../components/Top-Services';
import { HomeDoctor } from '../components/HomeDoctor';
import { HomeDifferent } from '../components/HomeDifferent';
import { HomeTechnology } from '../components/HomeTechnology';
import { HomeTestimonials } from '../components/HomeTestimonials';
import { Payments } from '../components/Payments';
import { Seo } from '../components/Seo';
import { InvisalignForm } from '../components/Invisalign-Form';

const Home: Page = () => (
    <>
        <DefaultLayout homepage previousPage="" currentPage="">
            <Seo
                title="Dentist in Linden, NJ | Family Dentist"
                description="Smilecraft Dental Studio specializes in family & cosmetic dentistry, including fillings, sealants, root canals, veneers, dental implants, and more. Call us!"
            />
            <HomeHero />
            <InvisalignForm />
            <TopServices />
            <HomeDoctor />
            <HomeDifferent />
            <HomeTechnology />
            <HomeTestimonials />
            <Payments />
        </DefaultLayout>
    </>
);

export default Home;
