import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';

const StyledTopServices = styled.div`
    padding-top: 2em;

    .image-label {
        font-size: 20px;
        color: ${colors.white};
        position: absolute;
        bottom: -0.5em;
        font-weight: 600;
    }
    .image {
        max-width: 305px;
        overflow: hidden;
        border-radius: 15px;
        img {
            transition: 0.2s ease-out;
            border-radius: 15px;
        }
        &:hover {
            border-radius: 15px;
            img {
                transform: scale(1.1);
                border-radius: 15px;
            }
            p {
                transform: translateY(-5px);
                opacity: 0.8;
            }
        }
    }

    .second {
        position: relative;
        top: 3em;
    }
    .photos {
        overflow-x: scroll;
    }
    h2 {
        text-align: center;
        color: ${colors.blueDark};
    }
    p {
        padding: 1em 1em;
    }
    .scroll {
        width: 100vw;
        display: flex;
        width: 1420px;
        padding: 1em 0em 8em 1em;
    }
    .main-image {
        filter: brightness(82%);
        transition: 0.1s ease-in;
        min-width: 305px;
        height: 364px;
        border-radius: 15px;
        cursor: pointer;

        &:hover {
            filter: brightness(92%);
            transition: 0.1s ease-in;
        }
    }
    div {
        margin-right: 0.5em;
    }
    @media ${device.laptopL} {
        .scroll {
            margin: 0 auto;
            padding: 1em 0em 10em 1em;
            display: flex;
            justify-content: center;
            img {
                width: 305px;
            }
        }
        .photos {
            overflow-x: hidden;
        }
        .text {
            margin: 3em auto 2em;
            p {
                width: 47%;
            }
            width: 80%;
            justify-content: space-around;
            display: flex;
        }
    }
`;

export const TopServices: React.FC = () => {
    return (
        <StyledTopServices>
            <div className="text">
                <h2>Our Top Services</h2>
                <p>
                    Located in Linden, NJ, we are your local, comprehensive dental solution for the
                    entire family. Magic Smile Dental can help you begin your journey to a healthy,
                    beautiful smile.
                </p>
            </div>
            <div className="photos">
                <div className="scroll">
                    <Link to="/dental-implants/">
                        <div className="image">
                            <div className="shadow"></div>
                            <StaticImage
                                imgStyle={{ borderRadius: '15px' }}
                                className="underlayer"
                                quality={100}
                                src="../images/fake-teeth.jpeg"
                                placeholder="none"
                                alt="fake set of implants"
                            />
                            <StaticImage
                                className="main-image"
                                imgStyle={{ borderRadius: '15px' }}
                                quality={100}
                                src="../images/fake-teeth.jpeg"
                                placeholder="none"
                                alt="fake set of implants"
                            />
                            <p className="image-label">Dental Implants</p>
                        </div>
                    </Link>
                    <Link to="/cosmetic-dentistry/invisalign/">
                        <div className="second image">
                            <div className="shadow"></div>
                            <StaticImage
                                imgStyle={{ borderRadius: '15px' }}
                                className="underlayer"
                                quality={100}
                                src="../images/invisalign.jpeg"
                                placeholder="none"
                                alt="invisalign"
                            />
                            <StaticImage
                                imgStyle={{ borderRadius: '15px' }}
                                quality={100}
                                className="main-image"
                                src="../images/invisalign.jpeg"
                                placeholder="none"
                                alt="invisalign"
                            />
                            <p className="image-label">Invisalign</p>
                        </div>
                    </Link>
                    <Link to="/cosmetic-dentistry/porcelain-veneers/">
                        <div className="image">
                            <div className="shadow"></div>
                            <StaticImage
                                imgStyle={{ borderRadius: '15px' }}
                                className="underlayer"
                                quality={100}
                                src="../images/all-on-4.jpeg"
                                placeholder="none"
                                alt="woman getting all-on-4 treatment"
                            />
                            <StaticImage
                                className="main-image"
                                imgStyle={{ borderRadius: '15px' }}
                                quality={100}
                                src="../images/all-on-4.jpeg"
                                placeholder="none"
                                alt="woman getting all-on-4 treatment"
                            />
                            <p className="image-label">Porcelain Veneers</p>
                        </div>
                    </Link>
                    <Link to="/periodontics/lanap-procedure">
                        <div className="second image">
                            <StaticImage
                                imgStyle={{ borderRadius: '15px' }}
                                className="underlayer"
                                quality={100}
                                src="../images/lanap-lapip.jpg"
                                placeholder="none"
                                alt="woman smiling"
                            />
                            <StaticImage
                                className="main-image"
                                imgStyle={{ borderRadius: '15px' }}
                                quality={100}
                                src="../images/lanap-lapip.jpg"
                                placeholder="none"
                                alt="woman smiling"
                            />
                            <p className="image-label">LANAP ® LASER TREATMENT</p>
                        </div>
                    </Link>
                </div>
            </div>
        </StyledTopServices>
    );
};
