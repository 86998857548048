import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { PaperForm } from './PaperForm';
import { Link } from 'gatsby';
import { Button } from './layout/StyledComponents';

const StyledContainer = styled.div`
    padding: 36px 0px;
`;
const StyledPaperForm = styled.div`
    padding: 36px 0px;
`;

const Form = styled.form`
    background: #daf3ff;
    margin: 15px;
    border-radius: 15px;
    padding: 35px 15px 15px;
    button:hover {
        filter: brightness(90%);
    }
    h3 {
        font-size: 20px;
        padding: 0 25px;
        text-align: center;
        line-height: 162.68%;
        /* identical to box height, or 39px */
        margin-top: 25px;
        /* Blue/blue3 */

        color: #0a75f3;
    }
    input,
    textarea {
        margin: 0.45em 0px;
    }
    button {
        margin: 2em auto;
        width: 335px;
        display: flex;
        justify-content: center;
    }

    .section {
        margin: 0.75em 0em;
        display: flex;
        flex: stretch;
        justify-content: space-between;
    }

    .item {
        display: flex;
        flex-direction: column;
        width: 100%;
        input,
        textarea {
            border: 1px solid #b3c3ce;
            border-radius: 4px;
            padding: 0.5em 0.25em;
            text-indent: 5px;
        }
        .half {
            width: 160px;
        }
    }
    @media ${device.tablet} {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .content-container {
            width: 330px;
        }
        img {
            width: 260px;
        }
    }
    @media ${device.laptop} {
        display: flex;
        align-items: center;
        width: 1062px;
        margin: 0 auto;
        justify-content: space-around;
        button {
            width: 100%;
        }
        .item {
            justify-content: space-between;
            .half {
                width: auto;
            }
            .spec-half {
                margin-right: 15px;
            }
        }
        .content-container {
            width: 460px;
        }
        img {
            width: 380px;
        }
    }
`;

export const InvisalignForm: React.FC = () => {
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce((obj, key) => {
            const value = formData.getAll(key).join(', ').trim();
            if (value) {
                obj[key] = value;
            }
            return obj;
        }, {} as Record<string, unknown>);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };
    return (
        <>
            <StyledContainer className="container">
                
                <Form>
                    <StaticImage
                        src="../images/invisalign-girl.png"
                        alt="girl smiling"
                        quality={100}
                    />
                    <div className="content-container">
                        <StyledPaperForm>
                            {/* <h3>Fill out your contact info to get special</h3>
                            <PaperForm /> */}
                            <a href="https://app.nexhealth.com/appt/magic-smile-dental" target="_blank" >
                                <Button className='book-btn' type="button">
                                   Book Online
                                </Button>
                            </a> 
                        </StyledPaperForm>
                        {/* <div className="section">
                            <div className="item">
                                <label htmlFor="fullName-invisalign">Full Name</label>
                                <input
                                    placeholder="eg: John"
                                    className=""
                                    type="text"
                                    id="fullName-invisalign"
                                    name="fullName-invisalign"
                                />
                            </div>
                        </div>
                        <div className="section">
                            <div className="item">
                                <label htmlFor="phone-invisalign">Phone Number</label>
                                <input
                                    className="spec-half half"
                                    type="tel"
                                    id="phone-invisalign"
                                    name="phone-invisalign"
                                    required
                                />
                            </div>

                            <div className="item">
                                <label htmlFor="email-invisalign">Email</label>
                                <input
                                    className="half"
                                    placeholder="e.g.: johndoe@gmail.com"
                                    type="email"
                                    id="email-invisalign"
                                    name="email-invisalign"
                                />
                            </div>
                        </div>

                        <div className="section">
                            <div className="item">
                                <label htmlFor="comments-invisalign">Comments</label>
                                <textarea
                                    name="comments-invisalign"
                                    id="comments-invisalign"
                                ></textarea>
                            </div>
                        </div>
                        {submitState === 'submitted' ? (
                            <>
                                <h3>
                                    Message sent! Somebody from our office will be in touch shortly.
                                </h3>
                            </>
                        ) : (
                            <Button
                                disabled={
                                    submitState === 'submitting' || submitState === 'submitted'
                                }
                                color="blue"
                                type="submit"
                            >
                                {submitState === 'submitting' ? 'Sending message...' : 'Submit'}
                            </Button>
                        )}*/}
                    </div> 
                </Form>
            </StyledContainer>
        </>
    );
};
