import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';

const BlueFiller = styled.div`
    position: relative;
    display: block;
    @media ${device.laptop} {
        .side-blue {
            top: -8em;
            display: block;
            height: 642px;
            width: 327px;
            background: ${colors.blueExtraDark};
            position: absolute;
        }
    }

    @media ${device.laptopL} {
        position: relative;
        display: block;
        .blue {
            top: -14em;
            display: block;
            height: 100px;
            width: 100vw;
            background: ${colors.blueExtraDark};
            position: absolute;
        }
    }
    @media ${device.desktopL} {
        .side-blue {
            min-width: 30vw;
        }
    }
`;

const StyledHomeTestimonials = styled.div`
    display: flex;
    padding: 2em 0em;
    flex-direction: column;
    align-items: center;
    background: ${colors.yellow};
    .girl-container {
        position: relative;
        .line {
            position: absolute;
            width: 200px;
            height: 1.5px;
            left: -1em;
            background: ${colors.blueExtraDark};
        }
        .line-one {
            top: 2em;
            left: -1.7em;
        }
        .line-two {
            top: 6em;
            left: -7em;
            transform: rotate(90deg);
        }
        .line-three {
            bottom: 1.8em;
            left: 8.6em;
        }
        .line-four {
            bottom: 6.2em;
            left: 14em;
            transform: rotate(90deg);
        }
    }
    .quote-container {
        position: relative;
    }
    .quote-left {
        left: 0.75em;
    }
    .quote-right {
        right: 1.5em;
    }

    .selector {
        img {
            /* cursor: pointer; */
        }
        display: flex;
        position: relative;
        left: 6em;
        margin-bottom: 2em;
        top: 3em;
        justify-content: center;
        p {
            font-size: 32px;
            font-family: 'Poppins';
            font-style: normal;
            padding: 0em 1.5em;
        }
    }
    .quote {
        height: auto;
        width: 50px;
        position: absolute;
        margin-top: 1em;
    }
    .review {
        font-family: 'Petrona';
        font-style: italic;
        font-weight: 500;
        position: relative;
        top: 1.75em;
        padding: 0em 4.5em;
        height: 220px;
        margin-bottom: 2.6em;
    }
    span {
        align-self: flex-start;
        font-weight: 400;
        margin-left: 4.5em;
    }
    .girl-smiling {
        width: 80vw;
        margin: 3em auto;
        height: 359px;
    }
    @media ${device.tablet} {
        .selector {
            margin-bottom: 6em;
            left: 15em;
            top: 5em;
        }
        .girl-container {
            position: relative;
            .line {
                position: absolute;
                width: 200px;
                height: 1.5px;
                left: -1em;
                background: ${colors.blueExtraDark};
            }
            .line-one {
                top: 2em;
                left: -3em;
            }
            .line-two {
                top: 6em;
                left: -7em;
                transform: rotate(90deg);
            }
            .line-three {
                bottom: 1.8em;
                left: 12.8em;
            }
            .line-four {
                bottom: 6.2em;
                left: 17em;
                transform: rotate(90deg);
            }
        }

        .girl-smiling {
            width: 349px;
            margin: 3em auto;
            height: 367px;
        }
    }
    @media ${device.laptop} {
        flex-direction: row;
        justify-content: center;
        .line-one {
            top: 2em;
            left: -1.6em;
        }
        .line-two {
            top: 6em;
            left: -7em;
            transform: rotate(90deg);
        }
        .line-three {
            bottom: 1.8em;
            left: 8.8em;
        }
        .line-four {
            bottom: 6.2em;
            left: 14em;
            transform: rotate(90deg);
        }
        .girl-container {
            margin-right: 4em;
            .line {
                background: #d9cb9e;
            }
        }
        .quote-container {
            width: 600px;
            padding: 3em 4.5em;
        }
        .quote-left {
            top: 1em;
            left: 4.5em;
        }
        .quote-right {
            right: 4.5em;
        }
        .flex-items {
        }
        span {
            padding: 2em 4em;
        }
        .girl-smiling {
            width: 349px;
            margin: 3em auto;
            height: 367px;
        }
    }
    @media ${device.laptopL} {
        padding-bottom: 6em;
        span {
            position: relative;
            top: 7em;
        }
        .girl-container {
            .line-one {
                width: 282px;
            }
            .line-three {
                left: 3em;
                background: ${colors.blueExtraDark};
                width: 522px;
            }
            .line-four {
                height: 1px;
                background: ${colors.blueExtraDark};
                left: 19em;
            }
        }

        .girl-smiling {
            width: 384px;
            margin: 3em auto;
            height: 417px;
        }
        margin-top: -8em;
    }
    @media ${device.desktopL} {
        justify-content: center;
        p {
            font-size: 1em;
        }
    }
`;

const reviews = [
    {
        review: 'Dr. Bensianoff and the staff are absolutely great. They take the time to perform procedures and explain everything along the way. I would definitely recommend Magic Smile to anyone who needs to get dental work done.',
        name: 'Leticia Scandroglio',
    },
    {
        review: 'I have been going to Magic Smile Dental for a few years now and I am always so pleased with how personable the dental hygienists are and the time they take to make my cleanings enjoyable. The dentists are always kind and I never feel like they are rushing through my appointment. I would highly recommend!',
        name: 'Danielle Maier',
    },
    {
        review: 'Love this place. The staff and dentists are all very helpful and patient. They take the time to explain everything step by step.',
        name: 'Alana Sosa',
    },
    {
        review: 'Very friendly and well spoken. Very nice that they call you to remind you on your next appointment. They are very flexible and soon to have a happy smile showing my teeth. Will recommend to all friends and family. Even more nice they have their own parking lot in the back easy to go in and out!',
        name: 'Jesse Soto',
    },
];

export const HomeTestimonials: React.FC = () => {
    const [indexState, setIndexState] = useState<number>(0);

    const nextButton = () => {
        if (indexState <= reviews.length - 1) {
            setIndexState(indexState => indexState + 1);
        } else {
            return;
        }
    };

    const prevButton = () => {
        if (indexState > 0) {
            setIndexState(indexState => indexState - 1);
        } else {
            return;
        }
    };

    return (
        <>
            <BlueFiller>
                <div className="blue"></div>
                <div className="side-blue"></div>
            </BlueFiller>
            <StyledHomeTestimonials>
                <div className="girl-container flex-items">
                    <div className="line line-one"></div>
                    <div className="line line-two"></div>
                    {indexState === 0 && (
                        <StaticImage
                            quality={100}
                            className="girl-smiling"
                            src="../images/girl-smiling-in-sun.jpeg"
                            alt="girl smiling in the sun"
                            placeholder="none"
                        />
                    )}
                    {indexState === 1 && (
                        <StaticImage
                            quality={100}
                            className="girl-smiling"
                            src="../images/black-girl-smiling.jpeg"
                            alt="black girl smiling"
                            placeholder="none"
                        />
                    )}
                    {indexState === 2 && (
                        <StaticImage
                            quality={100}
                            className="girl-smiling"
                            src="../images/hand-on-chin-girl.jpeg"
                            alt="black girl smiling"
                            placeholder="none"
                        />
                    )}
                    {indexState === 3 && (
                        <StaticImage
                            quality={100}
                            className="girl-smiling"
                            src="../images/arms-crossed-guy.jpeg"
                            alt="guy with arms crossed"
                            placeholder="none"
                        />
                    )}
                    <div className="line line-three"></div>
                    <div className="line line-four"></div>
                </div>
                <div className="flex-items">
                    {reviews.map((review, index) => {
                        if (indexState === index) {
                            return (
                                <div>
                                    <div className="quote-container">
                                        <StaticImage
                                            className="quote-left quote"
                                            src="../images/svgs/quote-left-gold.svg"
                                            placeholder="none"
                                            alt="golden quotation symbol"
                                        />
                                        <p className="review">{review.review}</p>
                                        <StaticImage
                                            className="quote-right quote"
                                            src="../images/svgs/quote-right-gold.svg"
                                            placeholder="none"
                                            alt="golden quotation symbol"
                                        />
                                    </div>
                                    <span>{review.name}</span>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                    <div className="selector">
                        {indexState > 0 ? (
                            <button
                                style={{ border: 'none', background: 'none' }}
                                onClick={() => prevButton()}
                            >
                                <StaticImage
                                    style={{ transform: 'scaleX(-1)' }}
                                    src="../images/svgs/arrow-right-black.svg"
                                    placeholder="none"
                                    alt="black arrow point left"
                                />
                            </button>
                        ) : (
                            <StaticImage
                                src="../images/svgs/arrow-left-grey.svg"
                                placeholder="none"
                                alt="grey arrow point left"
                            />
                        )}
                        <p>{indexState + 1}</p>
                        {indexState < reviews.length - 1 ? (
                            <button
                                style={{ border: 'none', background: 'none' }}
                                onClick={() => nextButton()}
                            >
                                <StaticImage
                                    src="../images/svgs/arrow-right-black.svg"
                                    placeholder="none"
                                    alt="black arrow point right"
                                />
                            </button>
                        ) : (
                            <StaticImage
                                style={{ transform: 'scaleX(-1)' }}
                                className="disabled"
                                src="../images/svgs/arrow-left-grey.svg"
                                placeholder="none"
                                alt="grey arrow point right"
                            />
                        )}
                    </div>
                </div>
            </StyledHomeTestimonials>
        </>
    );
};
